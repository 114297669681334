import { languages } from "monaco-editor";

export const ostwLanguageConfiguration: languages.LanguageConfiguration = {
    comments: {
        lineComment: "//",
        blockComment: ["/*", "*/"],
    },
    brackets: [
        ["{", "}"],
        ["(", ")"],
        ["[", "]"],
    ],
    autoClosingPairs: [
        { open: '"', close: '"', notIn: ["string", "comment"] },
        { open: "'", close: "'", notIn: ["string", "comment"] },
    ],
};

let keywords = ["rule", "for", "foreach", "void"];

export const ostwLanguage: languages.IMonarchLanguage = {
    keywords,
    tokenizer: {
        root: [
            // Keywords & variables
            {
                regex: /[\w$]+/,
                action: {
                    cases: {
                        "@keywords": "keyword",
                        "@default": "variable",
                    },
                },
            },
            // Comments
            [/\/\/.*?$/, "comment"],
            // Strings
        ],
    },
};
