import React from 'react';
import MonacoEditor from 'react-monaco-editor';
import { editor } from 'monaco-editor';
import { setupLanguage } from './jsInterp';

type MonacoEditor3Props = {
    language: string,
    value?: string,
    readOnly?: boolean,
    registerModel?: boolean
    model?: editor.ITextModel,
    fontSize?: number,
    theme?: string,
    height?: string,
    setEditor?: (current: editor.IEditor | undefined) => void
};

export const MonacoEditor3: React.FC<MonacoEditor3Props> = (props) => {
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [editor, setEditor] = React.useState<JSX.Element | undefined>(undefined);
    const container = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const startTask = async () => {
            await setupLanguage();

            setEditor(<MonacoEditor
                width={width}
                height={height}
                language={props.language}
                theme={props.theme}
                value={props.value}
                options={{
                    bracketPairColorization: {
                        enabled: true
                    },
                    autoClosingBrackets: 'always',
                    minimap: {
                        enabled: false
                    },
                    readOnly: props.readOnly,
                    scrollBeyondLastLine: false,
                    model: props.model,
                    matchBrackets: 'always',
                    fontSize: props.fontSize,
                    mouseWheelZoom: true
                }}
                editorDidMount={(editor, monaco) => {
                    props.setEditor?.(editor);
                }}
                editorWillUnmount={() => {
                    props.setEditor?.(undefined);
                }} />);
        };
        startTask();

        if (container.current) {
            const resizer = new ResizeObserver(c => {
                const w = container.current?.clientWidth ?? 0;
                const h = container.current?.clientHeight ?? 0;
                if ((w !== width || h !== height) && w !== 0 && h !== 0) {
                    setWidth(w);
                    setHeight(h);
                }
            });
            resizer.observe(container.current);

            return () => {
                resizer.disconnect();
            };
        }
    }, [props, width, height]);

    return <div style={{ width: '100%', height: props.height ?? '100%' }} ref={container}>
        {editor}
    </div>;
}