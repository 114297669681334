import React from 'react';

export function createSandboxButton(title: string, onClick?: () => void, key?: any) {
    return <div onClick={onClick}
        className='Ostw-sandbox-external-link'
        style={{ margin: '0px 5px 10px 5px' }}
        key={title}
    >{title}</div>
}

export const SandboxCopyButton: React.FC<{ title: string, onClick: () => void }> = props => {
    const [wasClicked, setWasClicked] = React.useState(false);
    const fadeTimer = React.useRef<NodeJS.Timeout>();

    const clickHandler = () => {
        setWasClicked(true);
        if (fadeTimer.current) {
            clearTimeout(fadeTimer.current);
        }
        fadeTimer.current = setTimeout(() => {
            setWasClicked(false);
            fadeTimer.current = undefined;
        }, 1000);
        props.onClick();
    };

    return <div style={{ margin: '10px 5px 10px 5px', position: 'relative' }}>
        {wasClicked ? <div className={'Ostw-sandbox-button-confirm'}>copied!</div> : undefined}
        <div onClick={clickHandler} className={'Ostw-sandbox-external-link'} >{props.title}</div>
    </div>
};

export function createDeleteButton(onClick?: () => void) {
    return createInlineButton('/icons/delete.svg', 'delete', onClick);
}

export function createEditButton(onClick?: () => void) {
    return createInlineButton('/icons/edit.svg', 'edit', onClick);
}

export function createInlineButton(icon: string, alt?: string, onClick?: () => void) {
    return <div className='Sandbox-inline-button'>
        <img src={icon}
            alt={alt}
            style={{ height: '100%' }}
            onClick={e => {
                e.stopPropagation();
                onClick?.();
            }} />
    </div>
}

export function createBetterInlineButton(icon: string, alt?: string, onClick?: () => void, autoHeight: boolean = false) {
    return <img src={icon} className='Sandbox-button'
        alt={alt}
        style={{ height: '100%' }}
        onClick={e => {
            e.stopPropagation();
            onClick?.();
        }} />
}