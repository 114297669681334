import React from 'react';

export const ProjectPanel: React.FC<{
    title: JSX.Element,
    description: JSX.Element,
    links: JSX.Element,
    bgImage: string
}> = props => {
    return <div className='Project'>
        <img src={props.bgImage} alt='' className='Project-bg' />
        <div className='Project-content'>
            <div className='Project-details'>
                <div className='Project-title'>{props.title}</div>
                <div className='Project-description'>{props.description}</div>
            </div>
            <div className='Project-links'>{props.links}</div>
        </div>
    </div>
};