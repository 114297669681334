import React, { useEffect } from 'react';
import { MonacoEditor3 } from './MonacoEditor3';
import { decompileWorkshop, onWorkshopCode, openOstwFile } from './jsInterp';
import { DragPanel, DragPanelDirection } from './DragPanel';
import { Project, ProjectFile, Save, saves, waitForInitialSaves } from './project';
import { editor } from 'monaco-editor';
import { SandboxCopyButton, createDeleteButton, createEditButton, createSandboxButton } from './SandboxButton';
import { getConflictingFiles, getNotableText } from './utils';
import ReactModal from 'react-modal';
import { ModalButton, SandboxIntroduction, SandboxModal } from './SandboxModal';
import { Examples } from './Examples';
import { SavesContainer } from './SavesDropdown';
import { Theme, ThemeSelector } from './ThemeSelector';
import { FontSizeSelector, getDefaultFontSize } from './FontSizeSelector';
import { Notifications } from './Notifications';

type LoadProject = (loader: () => Promise<Project | undefined>) => void;

type SetProjectFile = (newFile: ProjectFile | undefined) => void;

type SandboxProps = {};

export interface ProjectManager {
    /** The selected theme. */
    theme: string | undefined,
    /** The current font size. */
    fontSize: number | undefined,
    /** The currently opened project. */
    activeProject: Project | undefined,
    /** The currently opened file in the project. */
    activeFile: ProjectFile | undefined,
    /** The modal overlay DOM element. */
    modalOpened: boolean,
    /** Function to open a new file. */
    setProjectFile: SetProjectFile,
    /** Function to load a new project asynchronously. */
    loadProject: LoadProject,
    /** A function to save the current project. */
    saveProject: () => void,
    /** The function to delete a save. */
    deleteSave: (save: Save) => void,
    /** The function to rename a save. */
    renameSave: (save: Save) => void,
    /** Opens a modal with the provided content. */
    setModalContent: (content: React.ReactNode) => void,
    /** Closes the current modal. */
    closeModal: () => void
}

export const Sandbox: React.FC<SandboxProps> = () => {
    // Monaco editor reference
    let mainEditor = React.useRef<editor.IEditor>();
    // The active project state. Call 'changeProject' instead of 'setActiveProject'.
    const [activeProject, setActiveProject] = React.useState<Project | undefined>();
    // The opened file state. Call 'setProjectFileHandler' instead of 'setActiveFile'.
    const [activeFile, setActiveFile] = React.useState<ProjectFile | undefined>(activeProject?.defaultFile());
    // Generic modal.
    const [modalOpened, setModalOpened] = React.useState(false);
    const [modalContent, setModalContentValue] = React.useState<React.ReactNode>();
    // For manually triggering rerenders (use by calling 'triggerUpdate({})')
    const [, triggerUpdate] = React.useState({});
    // Theme and font size
    const [theme, setTheme] = React.useState<Theme>();
    const [fontSize, setFontSize] = React.useState(getDefaultFontSize());
    // Orientation
    const [orientation, setOrientation] = React.useState(getOrientation());

    // Called when the editor is loaded.
    const onEditor = (editor: editor.IEditor | undefined) => {
        // Save a ref to the monaco editor.
        mainEditor.current = editor;
    };

    // Function to update the currently opened file.
    const setProjectFileHandler = (newFile: ProjectFile | undefined) => {
        mainEditor.current?.setModel(newFile?.model ?? null);
        setActiveFile(newFile);
        // Recompile
        if (newFile) {
            openOstwFile(newFile.model);
        }
    };

    /** Opens the project save screen. */
    const openSaveScreen = (onSave?: () => void) => {
        const saveProject = (name: string) => {
            if (activeProject) {
                const save = new Save(name);
                save.add(activeProject);
                onSave?.();
            }
        };

        setModalContent(<SaveScreen onFinish={closeModal} addSave={saveProject} />);
    };

    /** Changes the active project. */
    const changeProject = (newProject: Project | undefined) => {
        const load = () => {
            // Load the new project.
            if (newProject) {
                newProject.load(projectUpdated);
                setProjectFileHandler(newProject.defaultFile());
            }
            else {
                setProjectFileHandler(undefined);
            }

            // Update state.
            setActiveProject(newProject);
        };

        // If another project is loaded, unload it.
        if (activeProject) {
            activeProject.close().then(() => load());
        } else {
            load();
        }
    };

    /** Asynchronously loads a new function with the provided loader.
     * Will ensure that current changes are not lost. */
    const loadProjectHandler = (loader: () => Promise<Project | undefined>) => {
        const execLoad = (loader: () => Promise<Project | undefined>) => {
            closeModal();
            loader().then(changeProject);
        };
        // Is the currently opened project unsaved?
        if (activeProject?.needsSave()) {
            // Current project will be lost, open modal.
            setModalContent(<div>
                <div>The current project is unsaved. Would you like to save it?</div>
                <ModalButton onClick={() => closeModal()}>Cancel</ModalButton>
                <ModalButton onClick={() => execLoad(loader)}>No</ModalButton>
                <ModalButton onClick={() => openSaveScreen(() => execLoad(loader))}>Yes</ModalButton>
            </div>);
        }
        else {
            execLoad(loader);
        }
    }

    /** Deletes a save. */
    const deleteSaveHandler = (save: Save) => {
        const execDeleteSave = (save: Save) => {
            if (activeProject?.linkedSave === save) {
                changeProject(Project.defaultProject());
            }
            save.delete(activeProject);
            closeModal();
        }

        setModalContent(<div>
            <div>Delete project '{save.name}'?</div>
            <ModalButton onClick={closeModal}>No</ModalButton>
            <ModalButton onClick={() => execDeleteSave(save)}>Yes</ModalButton>
        </div>);
    };

    /** Renames a save. */
    const renameSaveHandler = (save: Save) => {
        setModalContent(<SaveScreen onFinish={closeModal} initial={save.name} addSave={(name: string) => {
            save.rename(name).then(() => triggerUpdate({}));
        }} />);
    };

    /** Opens the overlay modal with the provided content. */
    const setModalContent = (content: React.ReactNode) => {
        setModalContentValue(content);
        setModalOpened(true);
    };

    /** Closes the current modal. */
    const closeModal = () => {
        setModalOpened(false);
    };

    /** Execute when the opened file is changed. */
    const projectUpdated = () => {
        triggerUpdate({});
    };

    useEffect(() => {
        // Rerender when saves are loaded.
        waitForInitialSaves().then(() => triggerUpdate({}));
    }, []);

    // No project loaded, open default.
    if (!activeProject) {
        changeProject(Project.defaultProject());
    }

    // Warn if leaving without saving
    // Observe window resizes
    React.useEffect(() => {
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            if (activeProject?.needsSave()) {
                e.preventDefault();
                e.returnValue = "";
            }
        };

        const onResize = (e: UIEvent) => {
            setOrientation(getOrientation());
        };

        window.addEventListener('beforeunload', onBeforeUnload);
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
            window.removeEventListener('resize', onResize);
        };
    });

    const manager: ProjectManager = {
        theme: theme?.id,
        fontSize,
        activeFile,
        activeProject,
        modalOpened: modalOpened,
        deleteSave: deleteSaveHandler,
        loadProject: loadProjectHandler,
        saveProject: () => openSaveScreen(),
        setProjectFile: setProjectFileHandler,
        renameSave: renameSaveHandler,
        setModalContent,
        closeModal
    };

    return <div className='Ostw-sandbox'>
        <div className='Ostw-sandbox-top'>
            <div className='Ostw-sandbox-file-info'>
                <span className='Ostw-sandbox-project-name'>📁{activeProject?.getProjectName()}</span>
                {' >'}
                <span className='Ostw-sandbox-file-name'>{activeFile?.name}</span>
            </div>
            <ThemeSelector setTheme={setTheme} currentTheme={theme} />
            <FontSizeSelector setFontSize={setFontSize} fontSize={fontSize} />
            <div className='Ostw-sandbox-top-title'>
                <a href='/'>deltin.dev</a>/<span className='Email'>sandbox</span>
            </div>
            <div className='Ostw-sandbox-top-external-links'>
                <ExternalLink title='Github' img="/icons/github-mark-white.svg" link="https://github.com/ItsDeltin/Overwatch-Script-To-Workshop" />
                <ExternalLink title='Discord' img="/icons/discord-mark-white.svg" link="https://discord.com/channels/570672959799164958/579278548666417162" />
            </div>
        </div>

        <DragPanel
            a={activeFile ?
                <MonacoEditor3 language='ostw'
                    value={activeFile?.model.getValue()}
                    registerModel
                    model={activeFile?.model}
                    setEditor={onEditor}
                    theme={theme?.id}
                    fontSize={fontSize} /> :
                <div />}

            b={<Sidebar width={undefined} height={undefined} manager={manager} />}
            orientation={orientation}
            defaultPos={.7} minPixel={100} maxPixel={300} />
        <SandboxIntroduction />
        <SandboxModal isOpen={modalOpened} close={closeModal}>
            {modalContent}
        </SandboxModal>
        <Notifications />
    </div>
};

function getOrientation() {
    if (window.innerWidth < 900)
        return DragPanelDirection.Vertical;
    return DragPanelDirection.Horizontal;
}

const ExternalLink: React.FC<{ title: string, img: string, link: string }> = ({ title, img, link }) => {
    return <a href={link} style={{ textDecoration: 'none', width: '100px' }} target="_blank" rel="noopener noreferrer">
        <div className='Ostw-sandbox-external-link'>
            <img src={img} alt={title + " logo"} />
            <span>{title}</span>
        </div>
    </a>
}

/** Sidebar component. */
const Sidebar: React.FC<{
    /** The width of the sidebar. */
    width: string | undefined,
    /** The height of the sidebar. */
    height: string | undefined,
    manager: ProjectManager
}> = props => {
    const [activeTab, setActiveTab] = React.useState<number>(0);

    return <div className='Ostw-sidebar' style={{ width: props.width, height: props.height }}>
        <Tabs items={['Project', 'Compiled Workshop Code', 'Examples']} active={activeTab} setActive={setActiveTab}>
            <ProjectTab props={props.manager} />
            <WorkshopOutput theme={props.manager.theme} fontSize={props.manager.fontSize} />
            <Examples manager={props.manager} setActiveTab={setActiveTab} />
        </Tabs>
    </div>
};

/** The tabs at the top of the sidebar. */
const Tabs: React.FC<React.PropsWithChildren<{
    active: number,
    setActive: (val: number) => void,
    items: string[]
}>> = ({ active, setActive, items, children }) => {
    return <div className='Sandbox-tab-menu'>
        <div className='Sandbox-tabs'>
            {items.map((name, i) => <div onClick={() => setActive(i)} className={active === i ? 'Sandbox-tab-button Sandbox-tab-button-active' : 'Sandbox-tab-button'} key={name}>
                {name}
            </div>)}
        </div>
        {React.Children.map(children, (c, i) =>
            <div className='Sidebar-tab-content' style={i === active ? {} : { display: 'none' }}>
                {c}
            </div>)}
    </div>
};

const WorkshopOutput: React.FC<{ theme: string | undefined, fontSize: number | undefined }> = props => {
    const [workshopCode, setWorkshopCode] = React.useState<string>("// compiling workshop...");

    // Listen to workshop code updates.
    useEffect(() => {
        onWorkshopCode.on('code', setWorkshopCode);
        return () => {
            onWorkshopCode.off('code', setWorkshopCode);
        }
    }, [setWorkshopCode]);

    // Code copy event
    const onCopy = () => {
        navigator.clipboard.writeText(workshopCode);
    };

    return <div className='Sidebar-stetch-item'>
        <SandboxCopyButton title='Copy Code' onClick={onCopy} />
        <MonacoEditor3 language='ow' value={workshopCode} readOnly theme={props.theme} fontSize={props.fontSize} />
    </div>
};

/** The 'Project' tab in the sidebar. */
const ProjectTab: React.FC<{ props: ProjectManager }> = ({ props }) => {
    const [renaming, setRenaming] = React.useState<number>();
    const [addingFile, setAddingFile] = React.useState(false);
    // Manually trigger an update when a file is deleted in the project.
    const [, triggerUpdate] = React.useState({});
    // Modal
    const [decompileModal, setDecompileModal] = React.useState<JSX.Element>();
    const allowDecompileEvent = React.useRef(true);

    // When the 'Add File' button is pressed.
    const addFileHandler = () => {
        setAddingFile(true);
    };

    // Opens the decompile modal.
    const decompileHandler = () => {
        setDecompileModal(<span>Copy the workshop code to your clipboard, then press <span className='Monospace'>ctrl+v</span> to decompile.</span>);
    };

    // This function is executed when a name is chosen
    // for a new file.
    const confirmAddedFileName = (value: string | undefined) => {
        setAddingFile(false);
        // truthy: File name is not undefined or empty.
        if (value && props.activeProject) {
            const newFile = props.activeProject.addFile(value, undefined);
            props.setProjectFile(newFile);
        }
    };

    // Decompile paste event
    React.useEffect(() => {
        if (decompileModal) {
            const func = async (e: Event) => {
                // useState may have this function registered multiple times,
                // 'allowDecompileEvent' ensures it will only happen once.
                if (e instanceof ClipboardEvent && allowDecompileEvent.current && props.activeProject) {
                    allowDecompileEvent.current = false;

                    const text = e.clipboardData?.getData("text");
                    if (text !== undefined) {
                        // Decompile
                        const result = await decompileWorkshop(text);
                        console.log('decompilation result: ' + JSON.stringify(result));

                        if (result.result === 'success') {
                            // Close modal
                            setDecompileModal(undefined);
                            // Add new file
                            const file = props.activeProject.addFile(
                                props.activeProject.getAvailableName("decompile", "ostw"),
                                result.code
                            );
                            // Update active file
                            props.setProjectFile(file);
                        } else if (result.result === "incompleted") {
                            // Syntax error
                            setDecompileModal(modalError(
                                `Decompilation failed at line ${result.range.start.line + 1}, character ${result.range.start.character + 1}`,
                                getNotableText(result.original, result.range)));
                        } else {
                            // exception
                            setDecompileModal(modalError('An internal error occured while decompiling:', result.exception));
                        }
                    }
                }
            };
            window.addEventListener("paste", func);
            return () => {
                window.removeEventListener('paste', func, true);
            };
        }
    }); // End decompilation effect

    // No project loaded.
    if (props.activeProject === undefined) {
        return <div />;
    }

    return <div>
        { // Unsaved project, show warning
            props.activeProject.needsSave() ? <div className='Sandbox-modal-content-error' style={{ margin: '10px' }}>
                The current project is unsaved.
                <div className='Ostw-sidebar-spacing' />
                {createSandboxButton('Save project as...', props.saveProject)}
            </div> : <div className='Ostw-sidebar-spacing' />}

        <SavesContainer manager={props} />
        <div className='Ostw-sidebar-spacing' />

        <div className='Row'>
            {createSandboxButton('Add File', addFileHandler)}
            {createSandboxButton('Decompile', decompileHandler)}
        </div>
        {addingFile ? <RenameFile
            initial=''
            conflicting={getConflictingFiles(props.activeProject)}
            confirm={confirmAddedFileName} key={-1} acceptOnBlur /> : undefined}
        {props.activeProject.files.map((document, i) => {
            // on click: open the file.
            const onClick = () => {
                // May fire immediately after project is deleted.
                if (!document.isDeleted) {
                    props.setProjectFile(document);
                }
            };
            // on double click: Rename the file.
            const onDoubleClick = () => {
                setRenaming(i);
            };
            // on delete
            const onDelete = () => {
                const doDelete = () => {
                    props.closeModal();

                    if (!props.activeProject) {
                        return;
                    }

                    // If the file being deleted is opened, switch to a new file.
                    if (props.activeFile === document) {
                        let newFile = i - 1;
                        newFile = newFile < 0 ? 1 : newFile;
                        props.setProjectFile(props.activeProject.files.at(newFile));
                    }
                    // External data changed; manually update component.
                    triggerUpdate({});
                    // Delete file from project.
                    props.activeProject.delete(i);
                };

                props.setModalContent(<div>
                    <div>Delete file '{document.name}'?</div>
                    <ModalButton onClick={props.closeModal}>No</ModalButton>
                    <ModalButton onClick={doDelete}>Yes</ModalButton>
                </div>);
            };

            // Is the current document being renamed?
            if (renaming === i) {
                return <RenameFile
                    initial={document.name}
                    conflicting={getConflictingFiles(props.activeProject, document)}
                    confirm={value => {
                        setRenaming(undefined);
                        if (value && value !== "") {
                            props.activeProject?.rename(i, value);
                        }
                    }} key={document.name} acceptOnBlur />;
            } else {
                const className = props.activeFile === document ? 'Project-file-opened' : 'Project-file';
                return <div className='Project-file-container' key={document.name}>
                    <div className={className} onClick={onClick} onDoubleClick={onDoubleClick}>
                        <span>{document.name}</span>
                    </div>
                    <div className='Row'>
                        {createEditButton(onDoubleClick)}
                        {createDeleteButton(onDelete)}
                    </div>
                </div>
            }

        })}
        <ReactModal
            isOpen={decompileModal !== undefined}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onAfterOpen={e => allowDecompileEvent.current = true}
            onRequestClose={e => setDecompileModal(undefined)}
            overlayClassName='Sandbox-modal-overlay'
            className='Sandbox-modal-content'
        >{decompileModal}</ReactModal>
    </div>
};

function modalError(description: JSX.Element | string, details: JSX.Element | string | undefined) {
    return <div className='Sandbox-modal-content-error'>
        {description}<br /><br /><span className='Monospace'>{details}</span>
    </div>;
}

const RenameFile: React.FC<{
    initial?: string,
    conflicting?: string[],
    confirm?: (value: string | undefined) => void,
    contentRef?: React.MutableRefObject<{ text: string, error: string | undefined } | undefined>,
    acceptOnBlur?: boolean,
    emptyError?: boolean
}> = props => {
    const [text, setText] = React.useState(props.initial ?? '');

    // check validity of text
    let error: string | undefined = undefined;

    if (text.includes('/')) {
        error = 'Name contains invalid characters';
    }
    else if (props.conflicting?.includes(text)) {
        error = 'There is already a file with that name';
    }
    else if (!text && props.emptyError) {
        error = 'Text cannot be empty';
    }

    if (props.contentRef) {
        props.contentRef.current = { text, error };
    }

    const apply = () => {
        if (error) {
            props.confirm?.(undefined)
        } else {
            props.confirm?.(text)
        }
    }

    return <div>
        <div className='Project-file-rename'>
            <input type='text'
                value={text}
                onChange={e => {
                    setText(e.target.value);
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        apply();
                    }
                    else if (e.key === 'Escape') {
                        props.confirm?.(undefined);
                    }
                }}
                onBlur={e => {
                    if (props.acceptOnBlur) {
                        apply();
                    }
                }}
                autoFocus
            />
        </div>
        {error ? <div className='Rename-notification'>{error}</div> : undefined}
    </div>;
};

const SaveScreen: React.FC<{
    onFinish: () => void,
    addSave: (name: string) => void,
    initial?: string
}> = props => {
    const content = React.useRef<{ text: string, error: string | undefined }>();

    const save = () => {
        if (content.current?.text && !content.current.error && content.current.text !== props.initial) {
            props.addSave(content.current.text);
        }
        props.onFinish();
    };

    const conflicting = saves.map(s => s.name).filter(s => s !== props.initial);

    return <div>
        Project name:
        <RenameFile contentRef={content} confirm={save} conflicting={conflicting} initial={props.initial} />
        <ModalButton onClick={() => props.onFinish()}>Cancel</ModalButton>
        <ModalButton onClick={save}>Save</ModalButton>
    </div>;
};