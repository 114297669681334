import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import { ProjectPanel } from './home/ProjectPanel';

function App() {
  return (
    <div className="App">
      <div className="Top">
        <div className='ContentBox'>
          <div className='MyName'>
            deltin.dev
          </div>
          {/* <div className='About'>
            I am a <span className='Lang'>C#</span> and <span className='Lang'>TypeScript</span> programmer.<br />
          </div> */}
          <div className='Navigation'>
            <a href='https://github.com/ItsDeltin'><div className='Project-button'>Github</div></a>
          </div>
          <div className='Projects'>
            <ProjectPanel
              title={<>Overwatch Script To Workshop [OSTW]</>}
              description={<>A programming language for creating Overwatch Workshop games.
                Features high level constructs such as a type system, structs, classes, and lambda expressions. A Visual
                Studio Code extension provides a coding experience that is expected of a modern language with realtime
                diagnostics, auto completion, symbol information, and more.
                <p>Technologies: <span className='Lang'>C#</span>, <span className='Lang'>TypeScript</span></p></>}
              links={<>
                <a href='https://github.com/ItsDeltin/Overwatch-Script-To-Workshop'><div className='Project-button'>Github Repository</div></a>
                <a href='sandbox'><div className='Project-button'>Online Sandbox</div></a>
              </>}
              bgImage='home/ostwbg.png'
            />
            <ProjectPanel
              title={<>Pathmap Editor</>}
              description={<>A pathfinding libary and navigation mesh generator for Overwatch custom games.
                The editor creates and debugs navigation meshes. Compiles into the 3 main workshop languages: <a href='https://github.com/ItsDeltin/Overwatch-Script-To-Workshop'>OSTW</a>, <a href='https://github.com/Zezombye/overpy'>OverPy</a>, and vanilla <a href='https://workshop.codes/editor'>Workshop</a>.
                The pathfinder supports path sharing and swarming for better performance when moving multiple entities.
                <p>Technologies: <span className='Lang'>OSTW</span>, <span className='Lang'>Python</span>, <span className='Lang'>Typescript</span></p></>}
              links={<>
                <a href='https://github.com/ItsDeltin/Pathmap-Editor'><div className='Project-button'>Github Repository</div></a>
                <a href='https://itsdeltin.github.io/Pathmap-editor/'><div className='Project-button'>Save Tool</div></a>
                <a href='https://workshop.codes/pathfinder'><div className='Project-button'>workshop.codes</div></a></>}
              bgImage='home/pathmapbg.png'
            />
          </div>
        </div>
      </div>
    </div >
  );
}

export default App;
