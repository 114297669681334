import React from 'react';
import { createBetterInlineButton } from './SandboxButton';

export const FontSizeSelector: React.FC<{
    fontSize: number,
    setFontSize: (value: number) => void
}> = props => {
    const setSize = (value: number) => {
        value = Math.min(Math.max(value, 8), 41);
        props.setFontSize(value);
        try {
            localStorage.setItem('font-size', value.toString());
        }
        catch { }
    };

    return <div className='Row' style={{ height: '100%', gap: '5px', marginLeft: '5px' }}>
        {createBetterInlineButton('/icons/zoom-minus.svg', 'zoom out', () => setSize(props.fontSize - 3))}
        {createBetterInlineButton('/icons/zoom-plus.svg', 'zoom in', () => setSize(props.fontSize + 3))}
    </div>
};

export function getDefaultFontSize() {
    const number = localStorage.getItem('font-size');
    if (number !== null) {
        try {
            return Number.parseFloat(number);
        }
        catch { }
    }

    return 20;
}