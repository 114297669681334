import React from 'react';
import { Project } from './project';
import { ProjectManager } from './Sandbox';
import { createSandboxButton } from './SandboxButton';

export const Examples: React.FC<{
    manager: ProjectManager,
    setActiveTab: (newTab: number) => void
}> = props => {
    return <div>
        <div className='Ostw-sidebar-spacing' />
        {examples.map((e, i) => createSandboxButton(`${i + 1}: ${e.name}`, () => props.manager.loadProject(async () => {
            const project = await e.load();
            props.setActiveTab(0);
            return project;
        })))}
    </div>
};

class Example {
    public constructor(public name: string, public files: string[]) { }

    public async load(): Promise<Project> {
        const project = new Project();

        for (const f of this.files) {
            const filename = f.split("/").pop();
            const content = await fetch(f);

            if (content.ok) {
                project.addFile(
                    filename ?? "unknown.ostw",
                    await content.text()
                );
            }
        }
        return project;
    }
}

const examples: Example[] = [
    new Example("Cursor Menu", [
        "/ostw/examples/cursor_menu/main.ostw",
        "/ostw/examples/cursor_menu/cursor_library.ostw",
        "/ostw/examples/cursor_menu/scroll_bar.ostw",
    ]),
];
