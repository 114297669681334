import { Uri, editor } from "monaco-editor";
import { closeModel, setupModel } from "./jsInterp";
import * as idb from "idb-keyval";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export const uriTable: Map<editor.ITextModel, Uri> = new Map();

export class Project {
    // note: make disposable and dispose models uwu
    static defaultProject(): Project {
        const project = new Project();
        project.addFile("untitled.ostw");
        return project;
    }

    static async fromStream(file: File): Promise<Project> {
        const project = new Project();

        const load = await JSZip.loadAsync(file.arrayBuffer());

        for (const file of Object.entries(load.files)) {
            const content = await file[1].async("string");
            project.addFile(file[0], content);
        }

        return project;
    }

    files: ProjectFile[] = [];
    private wasUpdated: boolean = false;
    private isLoaded: boolean = false;
    private onUpdate?: () => void;

    public constructor(public linkedSave?: Save | undefined) {}

    public addFile(
        name: string,
        content: undefined | string = undefined
    ): ProjectFile {
        const { internal, current } = this.getAvailableUri(name);
        const model = editor.createModel(
            content ?? "// " + name,
            "ostw",
            current
        );

        const newFile: ProjectFile = { name, model, internalUri: internal, isDeleted: false };
        this.files.push(newFile);

        if (this.linkedSave) {
            idb.update<string[]>(["files", this.linkedSave?.name], (files) => {
                if (files) {
                    if (!files.includes(name)) {
                        files.push(name);
                    }
                    return files;
                } else {
                    return [name];
                }
            });
        }

        model.onDidChangeContent(async (e) => {
            if (this.linkedSave) {
                await idb.set(
                    ["content", this.linkedSave.name, newFile.name],
                    model.getValue()
                );
            }
        });

        if (this.isLoaded) {
            this.loadFile(newFile);
            this.update();
        }

        return newFile;
    }

    public defaultFile(): ProjectFile | undefined {
        return this.files.at(0);
    }

    public async load(onUpdate: () => void) {
        this.onUpdate = onUpdate;
        if (this.isLoaded) {
            return;
        }
        this.isLoaded = true;

        for (const file of this.files) {
            await this.loadFile(file);
        }
    }

    private async loadFile(file: ProjectFile) {
        uriTable.set(file.model, file.internalUri);
        file.model.onDidChangeContent((e) => {
            if (this.update()) {
                this.onUpdate?.();
            }
        });
        await setupModel(file.model);
    }

    public async close() {        
        if (!this.isLoaded) {
            return;
        }
        this.isLoaded = false;

        for (const file of this.files) {
            uriTable.delete(file.model);
            await closeModel(file.model);
            file.model.dispose();
        }
    }

    public async delete(index: number) {
        const file = this.files[index];
        this.files.splice(index, 1);
        file.isDeleted = true;
        file.model.dispose();

        if (this.linkedSave) {
            await idb.del(["content", this.linkedSave.name, file.name]);
            await idb.update<string[]>(
                ["files", this.linkedSave.name],
                (files) => {
                    return files ? files.filter((s) => s !== file.name) : [];
                }
            );
        }
    }

    public async rename(index: number, newName: string) {
        const oldName = this.files[index].name;
        this.files[index].name = newName;

        if (this.linkedSave) {
            await idb.del(["content", this.linkedSave.name, oldName]);
            await idb.set(
                ["content", this.linkedSave.name, newName],
                this.files[index].model.getValue()
            );
            await idb.update<string[]>(
                ["files", this.linkedSave.name],
                (files) => {
                    if (files) {
                        const index = files.indexOf(oldName);
                        if (index !== -1) {
                            files[index] = newName;
                        } else {
                            files.push(newName);
                        }
                        return files;
                    } else {
                        return [newName];
                    }
                }
            );
        }
    }

    public getAvailableName(base: string, extension: string): string {
        let name = `${base}.${extension}`;

        let i = 1;
        while (this.isNameTaken(name)) {
            name = `${base}-${i}.${extension}`;
            i++;
        }

        return name;
    }

    public getAvailableUri(name: string): { internal: Uri; current: Uri } {
        const models = editor.getModels();
        let internal = Uri.parse(name);
        let current = internal;

        let i = 1;
        // eslint-disable-next-line
        while (models.some((m) => m.uri.toString() === current.toString())) {
            current = Uri.parse(`${i}-${name}`);
            i++;
        }

        return { internal, current };
    }

    public isNameTaken(name: string) {
        return this.files.some((f) => f.name === name);
    }

    public needsSave() {
        return this.linkedSave === undefined && this.wasUpdated;
    }

    public download() {
        const zip = new JSZip();

        this.files.forEach((file) => {
            zip.file(file.name, file.model.getValue());
        });

        zip.generateAsync({ type: "blob" }).then((content) =>
            saveAs(content, "project.zip")
        );
    }

    public getProjectName() {
        return (
            this.linkedSave?.name ??
            (this.needsSave() ? "untitled*" : "untitled")
        );
    }

    public update(): boolean {
        if (!this.wasUpdated) {
            this.wasUpdated = true;
            return true;
        }
        return false;
    }
}

export interface ProjectFile {
    name: string;
    internalUri: Uri,
    model: editor.ITextModel;
    isDeleted: boolean;
}

export class Save {
    public constructor(public name: string) {}

    public async load(): Promise<Project> {
        const project = new Project(this);

        const files = await this.getFiles();

        if (files) {
            const contents = await this.getContents(files);

            if (contents.length === files.length) {
                for (let i = 0; i < contents.length; i++) {
                    project.addFile(files[i], contents[i]);
                }
            }
        }

        return project;
    }

    public async add(linkedProject: Project) {
        linkedProject.linkedSave = this;

        // Add to list of saves.
        saves!.push(this);
        await idb.update<string[]>("saves", (saves) => {
            if (saves) {
                saves.push(this.name);
                return saves;
            } else {
                return [this.name];
            }
        });

        // Update list of files.
        let query: [IDBValidKey, any][] = [
            [["files", this.name], linkedProject.files.map((f) => f.name)],
        ];
        // Update file contents.
        query = query.concat(
            linkedProject.files.map((f) => {
                let x: [IDBValidKey, any] = [
                    ["content", this.name, f.name],
                    f.model.getValue(),
                ];
                return x;
            })
        );

        // Execute file list and file contents query.
        await idb.setMany(query);
    }

    public async delete(linkedProject: Project | undefined): Promise<void> {
        if (linkedProject) {
            linkedProject.linkedSave = undefined;
        }

        // Remove from global saves array.
        const index = saves.indexOf(this);
        if (index !== -1) {
            saves.splice(index, 1);
        }

        // Delete from list of saves.
        await idb.update<string[]>("saves", (saves) => {
            if (saves) {
                const index = saves.indexOf(this.name);
                if (index !== -1) {
                    saves.splice(index, 1);
                }
                return saves;
            } else {
                return [];
            }
        });

        // Delete files entry.
        const files = await this.getFiles();
        await idb.del(["files", this.name]);

        if (files) {
            await idb.delMany(files.map((f) => ["content", this.name, f]));
        }
    }

    public async rename(newName: string): Promise<void> {
        if (newName === this.name) {
            return;
        }

        // delete 'files/project'
        const deleteQuery: IDBValidKey[] = [["files", this.name]];
        let setQuery: [IDBValidKey, any][] = [];

        const files = await this.getFiles();
        if (files) {
            // Update files entry for new name.
            setQuery.push([["files", newName], files]);
            // Delete 'content/project/*'
            deleteQuery.push(files.map((f) => ["content", this.name, f]));

            // Pair each file with its content.
            const contents = (await this.getContents(files)).map(
                (content, i) => ({ name: files[i], content })
            );

            // Upload contents with new name.
            setQuery = setQuery.concat(
                contents.map<[IDBValidKey, any]>((c) => [
                    ["content", newName, c.name],
                    c.content,
                ])
            );
        }

        // Update save name in array of saves.
        await idb.update<string[]>("saves", (saves) => {
            if (saves) {
                const index = saves.indexOf(this.name);
                if (index !== -1) {
                    // Old name found, replace it.
                    saves[index] = newName;
                } else {
                    // Old name not found, just add new name.
                    saves.push(newName);
                }
                return saves;
            } else {
                // No save list; create new one.
                return [newName];
            }
        });

        // Execute delete queries.
        await idb.delMany(deleteQuery);
        // Execute set queries.
        await idb.setMany(setQuery);

        this.name = newName;
    }

    async getFiles() {
        return await idb.get<string[]>(["files", this.name]);
    }

    async getContents(files: string[]) {
        return await idb.getMany<string>(
            files.map((file) => ["content", this.name, file])
        );
    }
}

export function getSaves() {
    return idb.get<string[]>("saves");
}

export const saves: Save[] = [];

const initialSaves = getSaves().then((initialSaves) => {
    initialSaves?.forEach((save) => {
        saves.push(new Save(save));
    });
});

export async function waitForInitialSaves() {
    await initialSaves;
}
