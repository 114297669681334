import { editor } from "monaco-editor";
import { languages, Uri as MonacoUri } from "monaco-editor";
import {
    ScriptDiagnostics,
    diagnosticToMarker,
    monacoChangeToProtocolChange,
    CompletionItem as ProtocolCompletionItem,
    toMonacoCompletionItem,
    SignatureHelp,
    toMonacoSignatureHelp,
    toProtocolSignatureContext,
    Hover,
    toMonacoHover,
    DecompileResult,
} from "./protocol";
import { ostwLanguageConfiguration } from "./languageConfiguration";
import { loadWASM } from "onigasm";
import { Registry } from "monaco-textmate";
import { monaco } from "react-monaco-editor";
import { wireTmGrammars } from "monaco-editor-textmate";
import EventEmitter from "events";
import { uriTable } from "./project";
import { CompletionResponse, HoverResponse, MainNotification, MainRequest, SignatureResponse, WorkerNotification } from "./worker/workerTypes";

export const onWorkshopCode: EventEmitter = new EventEmitter();

let setupLanguagePromise: Promise<void> | undefined;
let resolveServer: (value: OstwServer) => void;
let server: Promise<OstwServer> = new Promise(resolve => resolveServer = resolve);

class OstwServer
{
    serverWorker: Worker;
    currentMessageId = 0;
    pendingRequests: {
        id: number,
        resolve: (value: any) => void
    }[] = [];

    public constructor()
    {
        // serverWorker = new Worker(/* webpackIgnore: true */'./ostw/AppBundle/main.js', { type: 'module' });
        this.serverWorker = new Worker(/* webpackChunkName: "ostw-server-worker" */ new URL('./worker/serverWorker.ts', import.meta.url), { type: 'module' });
        this.serverWorker.addEventListener('message', ({ data }) => {
            const notification: WorkerNotification = data;

            switch (notification.type)
            {
                case 'notification_diagnostics':
                    gotDiagnostics(notification.diagnostics);
                    break;
                
                case 'notification_code':
                    onWorkshopCode.emit("code", notification.code);
                    break;
                
                case 'notification_misc':
                    onWorkshopCode.emit("message", notification.message);
                    break;
                    
                case 'response':
                    const pendingIndex = this.pendingRequests.findIndex(pending => pending.id === notification.id);
                    if (pendingIndex !== -1)
                    {
                        this.pendingRequests[pendingIndex].resolve(notification.data);
                        this.pendingRequests.splice(pendingIndex, 1);
                    }
                    break;
            }
        });
    }

    public async notifyServer(notification: MainNotification)
    {
        this.serverWorker.postMessage(notification);
    }

    public async requestServer<T>(request: MainRequest) {
        const id = this.currentMessageId++;
        this.notifyServer({ type: 'request', idRequest: { id, request } });

        const promise = new Promise<any>(resolve => {
            this.pendingRequests.push({ id, resolve });
        });
        return await promise as T;
    }
}

function gotDiagnostics(diagnostics: string)
{
    editor.removeAllMarkers("owner");
    const scriptDiagnostics: ScriptDiagnostics[] = JSON.parse(diagnostics);

    scriptDiagnostics.forEach((e) => {
        // Find model attached to URI.
        const publishToModel = modelFromServerUri(e.uri);

        // Ensure that the model exists.
        if (publishToModel) {
            const diagnostics = e.diagnostics.map((d) =>
                diagnosticToMarker(d)
            );
            // Apply diagnostics to model.
            editor.setModelMarkers(publishToModel, "owner", diagnostics);
        } else {
            console.log(
                "Received diagnostics for nonexistant model: " + e.uri
            );
        }
    });
}

export async function setupLanguage() {
    if (setupLanguagePromise === undefined) {
        setupLanguagePromise = new Promise(async resolve => {
            const server = new OstwServer();
            resolveServer(server);
            await setupLanguageInner(server);
            resolve();
        });
    }
    await setupLanguagePromise;
}

async function setupLanguageInner(server: OstwServer) {
    const langName = "ostw";
    languages.register({ id: langName, extensions: ["ostw", "del"] });
    languages.setLanguageConfiguration(langName, ostwLanguageConfiguration);
    // languages.setMonarchTokensProvider(langName, ostwLanguage);

    // Add providers
    // Completion
    languages.registerCompletionItemProvider(langName, {
        async provideCompletionItems(model, position) {
            const items: ProtocolCompletionItem[] = await server.requestServer<CompletionResponse>({
                type: 'completion',
                uri: uri(model),
                position
            });

            return {
                suggestions: items.map((i) =>
                    toMonacoCompletionItem(
                        i,
                        position.lineNumber,
                        position.column
                    )
                ),
            };
        },
        triggerCharacters: ["."],
    });
    // Signature Help
    languages.registerSignatureHelpProvider(langName, {
        async provideSignatureHelp(model, position, token, context) {
            const signatureHelpJson: SignatureHelp = await server.requestServer<SignatureResponse>({
                type: 'signature',
                uri: uri(model),
                position,
                context: toProtocolSignatureContext(context)
            });

            return {
                value: toMonacoSignatureHelp(signatureHelpJson),
                dispose() {},
            };
        },
        signatureHelpTriggerCharacters: ["("],
        signatureHelpRetriggerCharacters: [","],
    });
    // Hover
    languages.registerHoverProvider(langName, {
        async provideHover(model, position, token) {
            const hover: Hover = await server.requestServer<HoverResponse>({
                type: 'hover',
                uri: uri(model),
                position: position
            });
            return toMonacoHover(hover);
        },
    });

    const legend = await server.requestServer<any>({ type: 'tokenData' });

    // Semantic Tokens
    languages.registerDocumentSemanticTokensProvider(langName, {
        getLegend() {
            return legend;
        },
        async provideDocumentSemanticTokens(model, lastResultId, token) {
            return await server.requestServer<any>({ type: 'semanticTokens' });
        },
        releaseDocumentSemanticTokens(resultId) {},
    });
    await loadWASM("/onigasm.wasm");

    // Workshop language
    const workshopName = "ow";
    languages.register({ id: workshopName, extensions: ["ow", "workshop"] });

    // Wire Tm language
    await wireTmGrammars(monaco, ostwRegistry.registry, ostwRegistry.grammar);
}

export async function setupModel(model: editor.IModel) {
    // In case model is disposed in between call and ostw ready.
    if (model.isDisposed()) {
        return;
    }

    await (await server).notifyServer({ type: 'addModel', uri: uri(model), content: model.getValue() });
    // Notify server when the model's content is changed.
    model.onDidChangeContent(
        async (e) => await (await server).notifyServer({
            type: 'updateModel',
            uri: uri(model),
            changes: e.changes.map((c) => monacoChangeToProtocolChange(c))
        })
    );
}

export async function closeModel(model: editor.IModel) {
    (await server).notifyServer({
        type: 'closeModel',
        uri: uri(model)
    });
}

function createLanguageRegistry(
    tmLanguage: string,
    lang: string,
    source: string
): { registry: Registry; grammar: Map<string, string> } {
    const registry = new Registry({
        getGrammarDefinition: async (scopeName, dependentScope) => {
            let name = '/ostw.tmLanguage.json';
            switch (scopeName)
            {
                case 'source.ow':
                    name = '/ow.tmLanguage.json';
                    break;
            }

            return {
                format: "json",
                content: await (await fetch(name)).text(),
            };
        },
    });

    const grammar = new Map();
    grammar.set('ostw', 'source.del');
    grammar.set('ow', 'source.ow');

    return { registry, grammar };
}

const ostwRegistry = createLanguageRegistry(
    "/ostw.tmLanguage.json",
    "ostw",
    "source.del"
);

export async function decompileWorkshop(
    code: string
): Promise<DecompileResult> {
    return await (await server).requestServer({
        type: 'decompile',
        text: code
    });
}

export async function openOstwFile(model: editor.ITextModel) {
    await (await server).notifyServer({ type: 'open', uri: uri(model) });
}

function uri(model: editor.ITextModel): string {
    return (uriTable.get(model) ?? model.uri).toString();
}

function modelFromServerUri(uriStr: string): editor.ITextModel | undefined {
    // Note: 'reverse' is a hack to make sure that the most recently loaded project
    // is prioritized. The sandbox seems to struggle cleaning up previous saves in the dev
    // environment but is probably fine in production.
    for (const [model, serverUri] of Array.from(uriTable.entries()).reverse()) {
        if (serverUri.toString() === uriStr) {
            return model;
        }
    }
    // fallback: check global models
    return editor.getModel(MonacoUri.parse(uriStr)) ?? undefined;
}
