import React from 'react';
import ReactModal from 'react-modal';

export const SandboxModal: React.FC<React.PropsWithChildren<{
    isOpen: boolean,
    close: () => void,
    overlayRef?: ((instance: HTMLDivElement) => void)
}>> = props => {
    return <ReactModal
        isOpen={props.isOpen}
        overlayClassName='Sandbox-modal-overlay'
        className='Sandbox-modal-content'
        onRequestClose={props.close}
        overlayRef={props.overlayRef}
    >
        {props.children}
    </ReactModal>
}

export const SandboxIntroduction: React.FC<{}> = () => {
    const [isOpen, setOpened] = React.useState(true);

    return <ReactModal isOpen={isOpen} overlayClassName='Sandbox-modal-overlay' className='Sandbox-modal-content' onRequestClose={e => setOpened(false)}>
        <div style={{ textAlign: 'left', lineHeight: '2em' }}>
            Controls:
            <div><span className='Sandbox-control'>F1</span>: Command palette</div>
            <div><span className='Sandbox-control'>F3</span>: Search</div>
            <div><span className='Sandbox-control'>ctrl+space</span>: View completion</div>
            <div><span className='Sandbox-control'>ctrl+shift+space</span>: View function signature</div>
        </div>
    </ReactModal>
};

export const ModalButton: React.FC<React.PropsWithChildren<{ onClick?: () => void }>> = props => {
    return <div onClick={props.onClick} className='Ostw-sandbox-external-link' style={{ display: 'inline-block', margin: '20px 5px 0px 5px' }}>{props.children}</div>
};