import monaco, {
    editor,
    MarkerSeverity,
    languages,
    Range as MonacoRange,
} from "monaco-editor";

export function diagnosticToMarker(diagnostic: Diagnostic): editor.IMarkerData {
    let severity: MarkerSeverity;

    switch (diagnostic.severity) {
        case "Error":
            severity = MarkerSeverity.Error;
            break;

        case "Warning":
            severity = MarkerSeverity.Warning;
            break;

        case "Info":
            severity = MarkerSeverity.Info;
            break;

        case "Hint":
            severity = MarkerSeverity.Hint;
            break;

        default:
            severity = MarkerSeverity.Error;
            break;
    }

    return {
        message: diagnostic.message,
        startLineNumber: diagnostic.range.start.line + 1,
        startColumn: diagnostic.range.start.character + 1,
        endLineNumber: diagnostic.range.end.line + 1,
        endColumn: diagnostic.range.end.character + 1,
        severity: severity,
    };
}

export function monacoChangeToProtocolChange(
    change: editor.IModelContentChange
): ChangeEvent {
    return {
        text: change.text,
        rangeLength: change.rangeLength,
        range: {
            start: {
                line: change.range.startLineNumber - 1,
                character: change.range.startColumn - 1,
            },
            end: {
                line: change.range.endLineNumber - 1,
                character: change.range.endColumn - 1,
            },
        },
    };
}

export function toMonacoCompletionItem(
    completionItem: CompletionItem,
    line: number,
    column: number
): languages.CompletionItem {
    return {
        label: completionItem.label,
        // Monaco expects insertText, but that isn't required in the
        // language server protocol.
        insertText: completionItem.insertText ?? completionItem.label,
        filterText: completionItem.filterText ?? completionItem.label,
        sortText: completionItem.sortText ?? completionItem.label,
        kind: languages.CompletionItemKind[
            completionItem.kind as keyof typeof languages.CompletionItemKind
        ],
        range: {
            startLineNumber: line,
            startColumn: column,
            endLineNumber: line,
            endColumn: column,
        },
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: completionItem.detail,
        documentation: completionItem.documentation,
    };
}

export function toMonacoSignatureHelp(
    signatureHelp: SignatureHelp
): languages.SignatureHelp {
    return {
        activeParameter: signatureHelp.activeParameter ?? -1,
        activeSignature: signatureHelp.activeSignature ?? -1,
        signatures: signatureHelp.signatures.map((s) => ({
            label: s.label,
            activeParameter: s.activeParameter ?? undefined,
            documentation: s.documentation,
            parameters: s.parameters.map((p) => ({ ...p })),
        })),
    };
}

export function toProtocolSignatureHelp(
    monacoSignatureHelp: languages.SignatureHelp | undefined
): SignatureHelp | undefined {
    if (monacoSignatureHelp === undefined) {
        return undefined;
    }

    return {
        activeParameter: monacoSignatureHelp.activeParameter,
        activeSignature: monacoSignatureHelp.activeSignature,
        signatures: monacoSignatureHelp.signatures.map((s) => ({
            activeParameter: s.activeParameter ?? null,
            documentation: s.documentation?.toString() ?? "",
            label: s.label,
            parameters: s.parameters.map((p) => ({
                label: p.label?.toString() ?? "",
                documentation: p.documentation?.toString() ?? "",
            })),
        })),
    };
}

export function toProtocolSignatureContext(
    monacoSignatureContext: languages.SignatureHelpContext
): SignatureContext {
    return {
        activeSignatureHelp:
            toProtocolSignatureHelp(
                monacoSignatureContext.activeSignatureHelp
            ) ?? null,
        isRetrigger: monacoSignatureContext.isRetrigger,
        signatureHelpTriggerKind: monacoSignatureContext.triggerKind.toString(),
        triggerCharacter: monacoSignatureContext.triggerCharacter ?? null,
    };
}

export function toMonacoHover(hover: Hover): languages.Hover {
    return {
        contents: hover.contents.map((markdownString) => ({
            value: markdownString,
            // html can be enabled here too if we want to be fancy.
        })),
        range: toMonacoRange(hover.range),
    };
}

export function toMonacoRange(range: Range): MonacoRange {
    return new MonacoRange(
        range.start.line + 1,
        range.start.character + 1,
        range.end.line + 1,
        range.end.character + 1
    );
}

export interface ChangeEvent {
    range: Range;
    rangeLength: number;
    text: string;
}

export interface Range {
    start: Position;
    end: Position;
}

export interface Position {
    line: number;
    character: number;
}

export interface ScriptDiagnostics {
    uri: string;
    diagnostics: Diagnostic[];
}

export interface Diagnostic {
    message: string;
    range: Range;
    severity: string;
}

export interface CompletionItem {
    label: string;
    kind: string;
    detail: string;
    documentationMarkdown: string;
    insertText: string; // | null
    filterText: string;
    sortText: string
    documentation: string
    // Note: Some items are omitted.
    // monaco.CompletionItem: node_modules\monaco-editor\esm\vs\editor\editor.api.d.ts
}

export interface SignatureHelp {
    activeParameter: number | null;
    activeSignature: number | null;
    signatures: Signature[];
}

export interface Signature {
    label: string;
    parameters: SignatureParameter[];
    activeParameter: number | null;
    documentation: string; // Convert to IMarkdown
}

export interface SignatureParameter {
    label: string; // note: Monaco accepts a [number, number] value type too?
    documentation: string; // Convert to IMarkdown
}

export interface SignatureContext {
    signatureHelpTriggerKind: string;
    triggerCharacter: string | null;
    isRetrigger: boolean;
    activeSignatureHelp: SignatureHelp | null;
}

export interface Hover {
    contents: string[];
    range: Range;
}

// ~ ostw specific, not part of LSP ~
export interface DecompileResult {
    result: "success" | "incompleted" | "exception";
    range: Range;
    exception: string;
    code: string;
    original: string;
}
