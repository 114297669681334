import { editor } from 'monaco-editor';
import React from 'react';

export const ThemeSelector: React.FC<{
    setTheme: (name: Theme) => void,
    currentTheme: Theme | undefined
}> = props => {
    const changeTheme = (name: string) => {
        try {
            localStorage.setItem('theme', name);
        }
        catch { }

        const theme = themeFromName(name)!;

        const set = () => {
            props.setTheme(theme);
            document.documentElement.style.setProperty('--sandbox-active-color', theme.lineColor!);
        };

        if (!importedThemes.includes(name)) {
            importedThemes.push(name);

            import(`monaco-themes/themes/${theme.name}.json`).then(load => {
                editor.defineTheme(theme.id, load as editor.IStandaloneThemeData);
                theme.lineColor = load.colors['editorCursor.foreground'] ?? 'orange';
                set();
            });
        }
        else {
            set();
        }
    }

    let theme = props.currentTheme?.name;
    if (theme === undefined) {
        theme = (themeFromName(localStorage.getItem('theme')) ?? themeFromName('GitHub Dark'))!.name
        changeTheme(theme);
    }

    return <div><select name="themes" onChange={e => changeTheme(e.target.value)} value={theme}>
        {themes.map(({ name }) => <option value={name} key={name}>{name}</option>)}
    </select></div>
};

const importedThemes: string[] = [];
const themes: Theme[] = [
    'Active4D',
    'All Hallows Eve',
    'Amy',
    'Birds of Paradise',
    'Blackboard',
    'Brilliance Black',
    'Brilliance Dull',
    'Chrome DevTools',
    'Clouds Midnight',
    'Clouds',
    'Cobalt',
    'Cobalt2',
    'Dawn',
    'Dominion Day',
    'Dracula',
    'Dreamweaver',
    'Eiffel',
    'Espresso Libre',
    'GitHub Dark',
    'GitHub Light',
    'GitHub',
    'IDLE',
    'idleFingers',
    'iPlastic',
    'Katzenmilch',
    'krTheme',
    'Kuroir Theme',
    'LAZY',
    'MagicWB (Amiga)',
    'Merbivore Soft',
    'Merbivore',
    'monoindustrial',
    'Monokai Bright',
    'Monokai',
    'Night Owl',
    'Nord',
    'Oceanic Next',
    'Pastels on Dark',
    'Slush and Poppies',
    'Solarized-dark',
    'Solarized-light',
    'SpaceCadet',
    'Sunburst',
    'Textmate (Mac Classic)',
    'Tomorrow-Night-Blue',
    'Tomorrow-Night-Bright',
    'Tomorrow-Night-Eighties',
    'Tomorrow-Night',
    'Tomorrow',
    'Twilight',
    'Upstream Sunburst',
    'Vibrant Ink',
    'Xcode_default',
    'Zenburnesque',
].map(t => ({
    name: t,
    id: t.replace(/[^a-z0-9-]/gmi, '')
}));

function themeFromName(name: string | null) {
    return themes.find(t => t.name === name);
}

export interface Theme {
    name: string,
    id: string,
    lineColor?: string
}